export const dataTable = () => {
  const table = document.querySelector('[id*="Datatable"]');
  if (table) {
    const dataTableOptions = {
      retrieve: true,
      dom: 'Bfrtip',
      scrollY: (table.dataset.scrolly ? Number(table.dataset.scrolly) : ''),
      scrollCollapse: (table.dataset.collapse ? table.dataset.collapse : false),
      scroller: (table.dataset.scroller ? table.dataset.scroller : false),
      pageLength: (table.dataset.pagelength ? Number(table.dataset.pagelength) : 50),
      order: [[(table.dataset.sorting ? Number(table.dataset.sorting) : 0), (table.dataset.sortingtype ? table.dataset.sortingtype : 'asc')]],
      lengthChange: false,
      bStateSave: true,
      fnStateSave: (oSettings, oData) => {
        localStorage.setItem('DataTables'+window.location.pathname, JSON.stringify(oData))
      },
      fnStateLoad: (oSettings) => {
        return JSON.parse(localStorage.getItem('DataTables'+window.location.pathname));
      },
      language: {
        "emptyTable": "Tabulka neobsahuje žádná data",
        "info": "Zobrazuji _START_ až _END_ z celkem _TOTAL_ záznamů",
        "infoEmpty": "Zobrazuji 0 až 0 z 0 záznamů",
        "infoFiltered": "(filtrováno z celkem _MAX_ záznamů)",
        "loadingRecords": "Načítám...",
        "zeroRecords": "Žádné záznamy nebyly nalezeny",
        "paginate": {
          "first": "První",
          "last": "Poslední",
          "next": "Další",
          "previous": "Předchozí"
        },
        "searchBuilder": {
          "add": "Přidat podmínku",
          "clearAll": "Smazat vše",
          "condition": "Podmínka",
          "conditions": {
            "date": {
              "after": "po",
              "before": "před",
              "between": "mezi",
              "empty": "prázdné",
              "equals": "rovno",
              "not": "není",
              "notBetween": "není mezi",
              "notEmpty": "není prázdné"
            },
            "number": {
              "between": "mezi",
              "empty": "prázdné",
              "equals": "rovno",
              "gt": "větší",
              "gte": "rovno a větší",
              "lt": "menší",
              "lte": "rovno a menší",
              "not": "není",
              "notBetween": "není mezi",
              "notEmpty": "není prázdné"
            },
            "string": {
              "contains": "obsahuje",
              "empty": "prázdné",
              "endsWith": "končí na",
              "equals": "rovno",
              "not": "není",
              "notEmpty": "není prázdné",
              "startsWith": "začíná na"
            },
            "array": {
              "equals": "rovno",
              "empty": "prázdné",
              "contains": "obsahuje",
              "not": "není",
              "notEmpty": "není prázdné",
              "without": "neobsahuje"
            }
          },
          "data": "Sloupec",
          "logicAnd": "A",
          "logicOr": "NEBO",
          "title": {
            "0": "Rozšířený filtr",
            "_": "Rozšířený filtr (%d)"
          },
          "value": "Hodnota",
          "button": {
            "0": "Rozšířený filtr",
            "_": "Rozšířený filtr (%d)"
          },
          "deleteTitle": "Smazat filtrovací pravidlo"
        },
        "autoFill": {
          "cancel": "Zrušit",
          "fill": "Vyplň všechny buňky textem <i>%d<i><\/i><\/i>",
          "fillHorizontal": "Vyplň všechny buňky horizontálně",
          "fillVertical": "Vyplň všechny buňky vertikálně"
        },
        "buttons": {
          "collection": "Kolekce <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"><\/span>",
          "copy": "Kopírovat",
          "copySuccess": {
            "1": "Zkopírován 1 řádek do schránky",
            "_": "Zkopírováno %d řádků do schránky"
          },
          "copyTitle": "Kopírovat do schránky",
          "csv": "CSV",
          "excel": "Excel",
          "pageLength": {
            "-1": "Zobrazit všechny řádky",
            "_": "Zobrazit %d řádků"
          },
          "pdf": "PDF",
          "print": "Tisknout",
          "colvis": "Viditelnost sloupců",
          "colvisRestore": "Resetovat sloupce",
          "copyKeys": "Zmáčkněte ctrl or u2318 + C pro zkopírování dat.  Pro zrušení klikněte na tuto zprávu nebo zmáčkněte esc.."
        },
        "searchPanes": {
          "clearMessage": "Smazat vše",
          "collapse": {
            "0": "Vyhledávací Panely",
            "_": "Vyhledávací Panely (%d)"
          },
          "count": "{total}",
          "countFiltered": "{shown} ({total})",
          "emptyPanes": "Žádné Vyhledávací Panely",
          "loadMessage": "Načítám Vyhledávací Panely",
          "title": "Aktivních filtrů - %d"
        },
        "select": {
          "cells": {
            "1": "Vybrán 1 záznam",
            "_": "Vybráno %d záznamů"
          },
          "columns": {
            "1": "Vybrán 1 sloupec",
            "_": "Vybráno %d sloupců"
          }
        },
        "aria": {
          "sortAscending": "Aktivujte pro seřazení vzestupně",
          "sortDescending": "Aktivujte pro seřazení sestupně"
        },
        "lengthMenu": "Zobrazit _MENU_ výsledků",
        "processing": "Zpracovávání...",
        "search": "Vyhledávání:",
        "thousands": ",",
        "datetime": {
          "previous": "Předchozí",
          "next": "Další",
          "hours": "Hodiny",
          "minutes": "Minuty",
          "seconds": "Vteřiny",
          "unknown": "-",
          "amPm": [
            "Dopoledne",
            "Odpoledne"
          ]
        },
        "editor": {
          "close": "Zavřít",
          "create": {
            "button": "Nový",
            "title": "Nový záznam",
            "submit": "Vytvořit"
          },
          "edit": {
            "button": "Změnit",
            "title": "Změnit záznam"
          }
        },
        "infoThousands": " "
      },
    };
    // noinspection JSCheckFunctionSignatures
    const dataTableInstance = $(table).DataTable(dataTableOptions);

    window.addEventListener('pagehide', function() {
      localStorage.removeItem('DataTables' + window.location.pathname);
      console.log('Local storage: '+'DataTables' + window.location.pathname+' deleted')
    });
  }
};