//jQuery
window.$ = require('jquery');
window.jQuery = require('jquery');

// Bootstrap 5
import 'bootstrap';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

// icons
import "bootstrap-icons/font/bootstrap-icons.min.css";
import "fontawesome-free/css/all.min.css";

// Datatables bs5
import "datatables.net";
import "datatables.net-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";

//Naja
import naja from 'naja';
naja.initialize();
// Nette forms
import netteForms from 'nette-forms';

// Main.css file
import './styles/main.css';

// Datatables
import { dataTable } from './dataTables.js';
dataTable();

// Toast for flashMessages
import { toast } from './toast.js';
toast();

import { tooltip } from './tooltip.js';
tooltip();

import { formValidation } from './formsValidation.js';
formValidation();

import { tooltipClose } from './tooltipClose.js';

window.Nette = netteForms;

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    const snippetId = event.detail.snippet.id;
    if (snippetId === 'snippet-logsControl-logsControl' ||
        snippetId === 'snippet-usersControl-usersTable' ||
        snippetId === 'snippet-applicationsControl-applicationsTable' ||
        snippetId === 'snippet-appAccessesControl-appAccessTable'
    )
    {
        dataTable();
        tooltip();
        tooltipClose();
    } else if (snippetId.match(/^snippet-usersControl-user-\d+$/))
    {
        tooltip();
        tooltipClose();
    }
});

naja.uiHandler.addEventListener('interaction', (event) => {
    if (event.detail.element.hasAttribute('data-confirm')
        && ! window.confirm(event.detail.element.getAttribute('data-confirm'))
    ) {
        event.preventDefault();
    }
});

naja.uiHandler.addEventListener('interaction', (event) => {
    if (event.detail.element.hasAttribute('data-send')) {
        const forms = document.querySelectorAll('.needs-validation');
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)
    }
});

naja.addEventListener('success', event => {
    // noinspection JSUnresolvedReference
    if (typeof event.detail.payload.closeModal !== 'undefined') {
        const modals = document.querySelectorAll('.modal');
        modals.forEach(modal => {
            const modalInstance = bootstrap.Modal.getInstance(modal);
            if (modalInstance) {
                modalInstance.hide();
            }
        });

        const modalBackdrops = document.querySelectorAll('.modal-backdrop');
        modalBackdrops.forEach(backdrop => {
            backdrop.parentNode.removeChild(backdrop);
        });
    }

    // noinspection JSUnresolvedReference
    if (typeof event.detail.payload.loginError !== 'undefined') {
        console.log('error');
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js';
        script.async = true;
        document.head.appendChild(script);
    }
});

naja.snippetHandler.addEventListener('afterUpdate', event => {
    if (event.detail.snippet.id === 'snippet--flashMessages') {
        toast();
    }
});

const loader = document.getElementById('loader');
naja.addEventListener('start', () => {
    loader.style.display = 'block';
    console.time('ajaxRequest');
});
naja.addEventListener('complete', () => {
    loader.style.display = 'none';
    console.timeEnd('ajaxRequest');
});